export const educationsData = [
  {
    id: 1,
    institute: "Sharif University (Nonprofit)",
    program: "Computer Networking & Management",
    period: "2000 - 2001",
    cssAddition: "",
    moreDsc:
      "Graduated in CNM program, created toproduce professional experts in Computer Networking & Management",
    sorting: 1,
    status: true,
  },
  {
    id: 2,
    institute: "APTECH Computer Education",
    program: "Aptech Certified Computer Professional",
    period: "2006 - 2009",
    cssAddition: "",
    moreDsc:
      "APTECH Computer Education Graduated in ACCP program, created to produce industry-ready professional experts in programming techniques & high demand technologies",
    sorting: 2,
    status: true,
  },
  {
    id: 3,
    institute: "Staffordshire University",
    program: "BSc in Business Information Systems",
    period: "2011 - 2015",
    cssAddition: "",
    moreDsc:
      "Graduated in Business Information Systems program provides a cross-section of business and technology focuses on the manager of information technology to provide efficiency and effectiveness or strategy.",
    sorting: 3,
    status: true,
  },
  {
    id: 4,
    institute: "University of Roehampton",
    program: "MBA in (Digital) e-Business Management",
    period: "2017 - 2019",
    cssAddition: "",
    moreDsc:
      "Master’s Degree Candidate pursuing MBA of Electronic (Digital) Business Management.",
    sorting: 4,
    status: true,
  },
];
