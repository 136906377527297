import React from 'react';

const Header = () => {
  return (
    <div className="jumbotron jumbotron-fluid bg-dark mb-5">
      <div className="jumbotron-background" style={{textAlign: 'center'}}>
        <img
          src={process.env.PUBLIC_URL + "/img/main-background.jpg"}
          alt="Background"
          className="blur"
        />
      </div>

      <div className="container text-white">
        <div className="row">
          <div className="col-lg-12">
            <div className="row">
              <div className="col-md-5 col-xs-12 text-center">
                <img
                  src={process.env.PUBLIC_URL + "/img/hooman-600x600.jpg"}
                  className="md-avatar rounded-circle size-5 z-depth-2"
                  alt="aligment"
                />
                <ul className="list-inline mt-3">
                  <li className="list-inline-item" title="Stack Overflow">
                    <a
                      href="https://stackoverflow.com/users/1851604/hooman"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn-social btn-stack-overflow"
                    >
                      <i className="fa fa-stack-overflow"></i>
                    </a>
                  </li>
                  <li className="list-inline-item" title="Linkedin">
                    <a
                      href="https://www.linkedin.com/in/hbfard"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn-social btn-linkedin"
                    >
                      <i className="fa fa-linkedin"></i>
                    </a>
                  </li>
                  <li className="list-inline-item" title="Youtube">
                    <a
                      href="https://www.youtube.com/user/hbfard"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn-social btn-youtube"
                    >
                      <i className="fa fa-youtube"></i>
                    </a>
                  </li>
                  <li className="list-inline-item" title="Sound Cloud">
                    <a
                      href="https://on.soundcloud.com/kKydZ5uaVPBjtD328"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn-social btn-soundcloud"
                    >
                      <i className="fa fa-soundcloud"></i>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-md-7 col-xs-12">
                <div>
                  <h1>Hooman Babaii Fard</h1>
                  <h6 className="lead">
                    Full Stack Developer, Business Information Systems Analyst
                  </h6>
                  <p className="mt-4">
                  Based in Munich, I am an enthusiastic, sophisticated, and results-oriented professional Full Stack Developer. 
                  I bring experience with versatile Front-end and Back-end technologies, coupled with expertise as a Business Information System Analyst. 
                  With a strong understanding of management and leadership styles, I am always eager to tackle new challenges and deliver high-quality work using cutting-edge tools and techniques.
                  </p>
                  <div className="my-2 mb-5">
                    {/* <a
                      href="/downloads/Hooman_Resume_PDF_v2.01.pdf"
                      target="_blank"
                    >
                      <button className="btn btn-sm btn-danger">Download my CV in PDF</button>
                    </a> */}
                    <a
                      href="/downloads/Hooman_Resume_WORD_v2.02.docx"
                      target="_blank"
                    >
                      <button className="btn btn-sm btn-info">Download my CV in Word</button>
                    </a>
                    {/* <button type="button" className="btn btn-sm btn-success">Download my CV in PDF</button> */}
                    {/* <Link className="btn btn-sm btn-primary" to="#contact" scroll={el => { const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset; const yOffset = -80; window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' }); }}>Contact me</Link> */}
                  </div>
                </div>
                <dl className="row">
                  <dt className="col-sm-3">Location</dt>
                  <dd className="col-sm-9">Munich Larger Urban Area, Starnberg, Germany</dd>
                  <dt className="col-sm-3 text-truncate">Email</dt>
                  <dd className="col-sm-9">hbfard@gmail.com</dd>
                  <dt className="col-sm-3">Cell-phone</dt>
                  <dd className="col-sm-9">+49 177 4414 807</dd>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;