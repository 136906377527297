export const experiencesData = [
  {
    id: 1,
    company: "Debugger",
    jobTitle: "Multimedia Designer & Developer",
    period: "1998 - 1999",
    cssAddition: "",
    moreDsc:
      "Working alongside the designer team implementing the designs, also developing multimedia e-Learning contents.",
    tags: [
      "Macromedia Director",
      "Macromedia Lingo",
      "Adobe Premiere",
      "Adobe Photoshop",
      "Corel Draw",
      "Sonic Soundforge",
    ],
    sorting: 1,
    status: false,
  },
  {
    id: 2,
    company: "Behandish",
    jobTitle: "Web/Multimedia Developer",
    period: "1999 - 2007",
    cssAddition: "",
    moreDsc:
      "Worked with a team of multimedia & web developers to create high quality multimedia contents & web application.",
    tags: [
      "Macromedia Director",
      "Adobe Dreamweaver",
      "Adobe Flash",
      "MS Classic ASP",
      "MS ASP.net C# 1.1",
      "MS SQL Server",
    ],
    sorting: 2,
    status: false,
  },
  {
    id: 3,
    company: "HBFard",
    jobTitle: "Owner & Director",
    period: "2007 - 2012",
    cssAddition: "",
    moreDsc:
      "Self-employed company focused on delivering cutting edge highly customized web applicationsa and platforms.",
    tags: [
      "HTML",
      "CSS",
      "jQuery",
      "MS ASP.net C# 2.0 - 3.5 - 4.0",
      "MS ASP.net Ajax",
      "MS LINQ",
      "MS SQL Server",
    ],
    sorting: 3,
    status: true,
  },
  {
    id: 4,
    company: "TeacherBird (Malaysia)",
    jobTitle: "Software Developer",
    period: "2012 - 2015",
    cssAddition: "",
    moreDsc:
      "Created two Learning Management Systems alongside the e-Learning content developers, design team and also other software developers.",
    tags: [
      "HTML 4.x",
      "CSS 2.x",
      "jQuery 1.x",
      "Ajax",
      "MS ASP.net C# 4.5",
      "MS LINQ",
      "MS SQL Server",
    ],
    sorting: 4,
    status: true,
  },
  {
    id: 5,
    company: "Bizmatch Res Snd Bhd (Malaysia)",
    jobTitle: "Software Developer",
    period: "2015 - 2017",
    cssAddition: "",
    moreDsc:
      "Created a complex central financial web platform consists of another 4 connected web portal platforms",
    tags: [
      "HTML 5.x",
      "CSS 3.x",
      "jQuery 2.x",
      "Ajax",
      "Bootstrap 3.x",
      "MS ASP.net C# 4.x MVC 5",
      "MS Razor",
      "MS Web API 2.x",
      "MS EF 6.x & LINQ",
      "MS SQL Server",
    ],
    sorting: 5,
    status: true,
  },
  {
    id: 6,
    company: "Dahlbeer GmbH (Germany)",
    jobTitle: "Software Developer",
    period: "2017 - 2018",
    cssAddition: "",
    moreDsc:
      "Created an internal portal web platform along with a web application.",
    tags: [
      "HTML 5.x",
      "CSS 3.x",
      "jQuery 3.x",
      "Classic JavaScript",
      "Ajax",
      "Bootstrap 4.x",
      "MS ASP.net C# 4.x MVC 5",
      "MS Razor",
      "MS Web API 2.x",
      "MS EF 6.x & LINQ",
      "MS SQL Server",
    ],
    sorting: 6,
    status: true,
  },
  {
    id: 7,
    company: "Alten GmbH (Germany)",
    jobTitle: "Front-end Developer",
    period: "2019",
    cssAddition: "",
    moreDsc:
      "Working alongside the developer team implementing internal customized solutions to address client necessities.",
    tags: [
      "HTML 5.x",
      "CSS 3.x",
      "Modern JavaScript ES6",
      "Bootstrap 4.x",
      "MS Sharepoint Portal Server",
    ],
    sorting: 7,
    status: true,
  },
  {
    id: 8,
    company: "Brainworks computer GmbH (Germany)",
    jobTitle: "Full-stack Developer",
    period: "2020 - 2022",
    cssAddition: "",
    moreDsc:
      "Working alongside the developer team implementing in house e-Commerce and CRM web platforms.",
    tags: [
      "HTML 5.x",
      "CSS 3.x",
      "React / Next js",
      "TypeScript",
      "React Bootstrap 4.x, 5.x",
      "Node.js",
      "MySql",
    ],
    sorting: 8,
    status: true,
  },
  {
    id: 9,
    company: "MENTZ GmbH (Germany)",
    jobTitle: "Full-stack Developer",
    period: "2022 - Present",
    cssAddition: "",
    moreDsc:
      "Working alongside a team of developers and support, implementing cutting edge e-mobility web platform used by well-known global customers",
    tags: [
      "HTML 5.x",
      "CSS 3.x",
      "React / Next js",
      "Modern JS ES6",
      "Ant Design 4.x, 5.x",
      "Node.js",
      "MongoDB",
      "Docker",
    ],
    sorting: 9,
    status: true,
  },
];
