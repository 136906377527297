import React from 'react';
import { Nav, Navbar } from "react-bootstrap";
import { NavHashLink as NavLink } from 'react-router-hash-link';

const NavBar = () => {
  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      bg="dark"
      variant="dark"
      className="p-3"
      sticky="top"
      style={{ opacity: "0.9" }}
    >
      <Navbar.Brand href="#home">Hooman Babaii Fard</Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="ml-auto">
          <NavLink to="#skills" className="mx-2" activeClassName="text-light" scroll={el => { const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset; const yOffset = -80; window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' }); }}>Skills</NavLink>
          <NavLink to="#experiences" className="mx-2" activeClassName="text-light" scroll={el => { const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset; const yOffset = -80; window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' }); }}>Experiences</NavLink>
          <NavLink to="#educations" className="mx-2" activeClassName="text-light" scroll={el => { const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset; const yOffset = -80; window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' }); }}>Educations</NavLink>
          {/* <NavLink to="#contact" className="mx-2" activeClassName="text-light" scroll={el => { const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset; const yOffset = -80; window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' }); }}>Contact</NavLink> */}
        </Nav>
      </Navbar.Collapse>
    </Navbar>);
}

export default NavBar;