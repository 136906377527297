import React, { useState, useEffect } from 'react';
import SkillsCard from './SkillsCard';
import { skillsData } from "../skillsService"

const Skills = ({ title, query }) => {
  const [skills, setSkills] = useState([]);

  const fetchSkills = async () => {
    const response = await skillsData; //.filter(c => c.visible === true).sort((a, b) => a.pricing.price - b.pricing.price);
    setSkills(response);
  };
  useEffect(() => {
    fetchSkills();
  }, []);

  return (
    <React.Fragment>
      <h5 className="font-weight-bold mb-4">{title}</h5>
      <div className="card bg-light">
        <div className="card-body pt-0">
          <SkillsCard data={skills} query={query}></SkillsCard>
        </div>
      </div>
    </React.Fragment>);
}

export default Skills;