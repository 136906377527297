export const skillsData = [
  {
    id: 3,
    title: "Node.js",
    category: "backend",
    measure: "90",
    cssAddition: "bg-success",
    moreDsc: "",
    placement: {
      isSticky: false,
      parentId: 0,
    },
    sorting: 3,
    status: true,
  },
  {
    id: 1,
    title: "ASP.net C# MVC 5 | .net Core 2.x",
    category: "backend",
    measure: "85",
    cssAddition: "bg-success",
    moreDsc: "",
    placement: {
      isSticky: false,
      parentId: 0,
    },
    sorting: 1,
    status: true,
  },
  {
    id: 2,
    title: ".net Core 2.x",
    category: "backend",
    measure: "70",
    cssAddition: "bg-warning",
    moreDsc: "",
    placement: {
      isSticky: true,
      parentId: 1,
    },
    sorting: 2,
    status: true,
  },
  
  {
    id: 4,
    title: "WEB API 2.x | WEB API Core 2.x",
    category: "backend",
    measure: "80",
    cssAddition: "bg-success",
    moreDsc: "",
    placement: {
      isSticky: false,
      parentId: 0,
    },
    sorting: 4,
    status: true,
  },
  {
    id: 5,
    title: "WEB API Core 2.x",
    category: "backend",
    measure: "85",
    cssAddition: "bg-warning",
    moreDsc: "",
    placement: {
      isSticky: true,
      parentId: 4,
    },
    sorting: 5,
    status: true,
  },
  {
    id: 6,
    title: "MS Test v2 Unit testing framework",
    category: "backend",
    measure: "60",
    cssAddition: "bg-success",
    moreDsc: "",
    placement: {
      isSticky: false,
      parentId: 0,
    },
    sorting: 6,
    status: true,
  },
  {
    id: 11,
    title: "React.js | Next.js 12.x",
    category: "frontend",
    measure: "90",
    cssAddition: "bg-danger",
    moreDsc: "",
    placement: {
      isSticky: false,
      parentId: 0,
    },
    sorting: 11,
    status: true,
  },
  {
    id: 12,
    title: "Next.js",
    category: "frontend",
    measure: "90",
    cssAddition: "bg-info",
    moreDsc: "",
    placement: {
      isSticky: true,
      parentId: 11,
    },
    sorting: 13,
    status: true,
  },
  {
    id: 13,
    title: "Modern JavaScript (ES6) | TypeScript",
    category: "frontend",
    measure: "90",
    cssAddition: "bg-danger",
    moreDsc: "",
    placement: {
      isSticky: false,
      parentId: 0,
    },
    sorting: 12,
    status: true,
  },
  {
    id: 1,
    title: "TypeScript",
    category: "frontend",
    measure: "80",
    cssAddition: "bg-info",
    moreDsc: "",
    placement: {
      isSticky: true,
      parentId: 12,
    },
    sorting: 13,
    status: true,
  },
  // {
  //   id: 13,
  //   title: "Razor View 5.x (.net)",
  //   category: "frontend",
  //   measure: "95",
  //   cssAddition: "bg-danger",
  //   moreDsc: "",
  //   placement: {
  //     isSticky: false,
  //     parentId: 0,
  //   },
  //   sorting: 13,
  //   status: true,
  // },
  {
    id: 14,
    title: "HTML 5.x & CSS 3.x",
    category: "frontend",
    measure: "90",
    cssAddition: "bg-danger",
    moreDsc: "",
    placement: {
      isSticky: false,
      parentId: 0,
    },
    sorting: 14,
    status: true,
  },
  {
    id: 15,
    title: "Ant Design 4.x & 5.0x | React Bootstrap 4.x & 5.0x",
    category: "frontend",
    measure: "90",
    cssAddition: "bg-danger",
    moreDsc: "",
    placement: {
      isSticky: false,
      parentId: 0,
    },
    sorting: 15,
    status: true,
  },
  {
    id: 16,
    title: "React Bootstrap 4.x & 5.0x",
    category: "frontend",
    measure: "90",
    cssAddition: "bg-info",
    moreDsc: "",
    placement: {
      isSticky: true,
      parentId: 16,
    },
    sorting: 16,
    status: true,
  },
  {
    id: 17,
    title: "Mobile Development (React-Native)",
    category: "frontend",
    measure: "60",
    cssAddition: "bg-danger",
    moreDsc: "",
    placement: {
      isSticky: false,
      parentId: 0,
    },
    sorting: 17,
    status: true,
  },
  {
    id: 25,
    title: "MongoDB (noSQL Based Des & Dev)",
    category: "database",
    measure: "80",
    cssAddition: "bg-info",
    moreDsc: "",
    placement: {
      isSticky: false,
      parentId: 0,
    },
    sorting: 25,
    status: true,
  },
  {
    id: 21,
    title: "Code First | Model First | DB First",
    category: "database",
    measure: "90",
    cssAddition: "bg-info",
    moreDsc: "",
    placement: {
      isSticky: false,
      parentId: 0,
    },
    sorting: 21,
    status: true,
  },
  {
    id: 22,
    title: "Model First",
    category: "database",
    measure: "95",
    cssAddition: "deep-purple accent-1",
    moreDsc: "",
    placement: {
      isSticky: true,
      parentId: 21,
    },
    sorting: 22,
    status: true,
  },
  {
    id: 23,
    title: "DB First",
    category: "database",
    measure: "85",
    cssAddition: "green accent-3",
    moreDsc: "",
    placement: {
      isSticky: true,
      parentId: 21,
    },
    sorting: 23,
    status: true,
  },
  {
    id: 24,
    title: "SQL Based Des & Dev",
    category: "database",
    measure: "90",
    cssAddition: "bg-info",
    moreDsc: "",
    placement: {
      isSticky: false,
      parentId: 0,
    },
    sorting: 24,
    status: true,
  },
  {
    id: 26,
    title: "Entity Framework 6.x, Core & LINQ",
    category: "database",
    measure: "90",
    cssAddition: "bg-info",
    moreDsc: "",
    placement: {
      isSticky: false,
      parentId: 0,
    },
    sorting: 26,
    status: true,
  },
  {
    id: 27,
    title: "Sequelize (MySql & Node)",
    category: "database",
    measure: "90",
    cssAddition: "bg-info",
    moreDsc: "",
    placement: {
      isSticky: false,
      parentId: 0,
    },
    sorting: 26,
    status: true,
  },
  {
    id: 31,
    title: "e-Business architecture",
    category: "management",
    measure: "90",
    cssAddition: "warning-color-dark",
    moreDsc: "",
    placement: {
      isSticky: false,
      parentId: 0,
    },
    sorting: 31,
    status: true,
  },
  {
    id: 32,
    title: "e-Marketing strategies",
    category: "management",
    measure: "80",
    cssAddition: "warning-color-dark",
    moreDsc: "",
    placement: {
      isSticky: false,
      parentId: 0,
    },
    sorting: 32,
    status: true,
  },
  {
    id: 33,
    title: "e-Business implementation",
    category: "management",
    measure: "85",
    cssAddition: "warning-color-dark",
    moreDsc: "",
    placement: {
      isSticky: false,
      parentId: 0,
    },
    sorting: 33,
    status: true,
  },
  {
    id: 34,
    title: "Evaluation | Reporting",
    category: "management",
    measure: "90",
    cssAddition: "warning-color-dark",
    moreDsc: "",
    placement: {
      isSticky: false,
      parentId: 0,
    },
    sorting: 34,
    status: true,
  },
  {
    id: 35,
    title: "Reporting",
    category: "management",
    measure: "85",
    cssAddition: "teal darken-1",
    moreDsc: "",
    placement: {
      isSticky: true,
      parentId: 34,
    },
    sorting: 35,
    status: true,
  },
  {
    id: 36,
    title: "SCRUM (Agile environment)",
    category: "management",
    measure: "85",
    cssAddition: "warning-color-dark",
    moreDsc: "",
    placement: {
      isSticky: false,
      parentId: 0,
    },
    sorting: 36,
    status: true,
  },
];
