import React, { useState, useEffect } from 'react';
import { MDBBadge, MDBAnimation } from "mdbreact";
import { educationsData } from '../educationsService';

const TimelineEdu = ({ query }) => {
  const [timelineEduData, setTimelineEduData] = useState([]);

  const fetchEducations = async () => {
    const response = await educationsData.filter(c => c.status === true).sort((b, a) => a.sorting - b.sorting);
    setTimelineEduData(response);
  };
  useEffect(() => {
    fetchEducations();
  }, []);

  return (
    <div className="timeline-edu">
      <ul>
        {timelineEduData && timelineEduData.map((item, index) => (
          <li key={item.id}>
            <div className="content">
              <h3>{item.institute}</h3>
              <h5>
                <MDBBadge color="default-color font-weight-normal">
                  {item.program}
                </MDBBadge>
              </h5>
              <div className="more-dsc">
                <MDBAnimation reveal type={`${index % 2 === 0 ? "fadeInRight" : "fadeInLeft"}`}>
                  {item.moreDsc}
                </MDBAnimation>
              </div>
            </div>
            <div className="time">
              <h4>{item.period}</h4>
            </div>
          </li>
        ))}

        <div style={{ clear: "both" }}></div>
      </ul>
    </div>);
}

export default TimelineEdu;