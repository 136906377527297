import React, { useState, useEffect } from 'react';
import { MDBAnimation } from "mdbreact";
import { experiencesData } from '../experiencesService';

const Timeline = ({ query }) => {
  const [timelineData, setTimelineData] = useState([]);

  const fetchExperiences = async () => {
    const response = await experiencesData.filter(c => c.status === true).sort((b, a) => a.sorting - b.sorting);
    setTimelineData(response);
  };
  useEffect(() => {
    fetchExperiences();
  }, []);

  return (
    <ul className="timeline">
      {timelineData && timelineData.map((item, index) => (
        <li className={`${index % 2 === 0 ? "" : "timeline-inverted"}`} key={item.id}>
          <div className="timeline-badge">
            <a href="#/"><i className={`fa fa-circle ${index % 2 === 0 ? "" : "invert"}`} id=""></i></a>
          </div>
          <div className="timeline-panel rounded">
            <div className="timeline-heading">
              <MDBAnimation reveal type="fadeIn">
                <h4>{item.company}</h4>
              </MDBAnimation>
            </div>
            <div className="timeline-content-info special-color">
              <span className="timeline-content-info-title">
                <i className="fa fa-certificate" aria-hidden="true"></i>
                {item.jobTitle}
              </span>
              <span className="timeline-content-info-date text-right">
                <i className="fa fa-calendar-o" aria-hidden="true"></i>
                {item.period}
              </span>
            </div>
            <div className="timeline-body">
              <div>
                <MDBAnimation reveal type="fadeInDown">
                  {item.moreDsc}
                </MDBAnimation>
              </div>
            </div>
            <div className="timeline-footer">
              <ul className="content-skills">
                {item.tags && item.tags.map(tag => <li key={tag}>{tag}</li>)}
              </ul>
            </div>
          </div>
        </li>
      ))}

      <li className="clearfix no-float"></li>
    </ul>);
}

export default Timeline;