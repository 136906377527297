import React from 'react';

const SkillsCard = ({ data, query }) => {
  data = data.filter(s => s.category === query);

  return (
    <React.Fragment>
      {data && data.map(item => (
        <div key={item.id}>
          {(() => {
            if (item.placement.isSticky === false) {
              return (<h6 className="card-title small mt-4">
                {item.title}
              </h6>);
            }
          })()}

          <div className="card-text">
            {(() => {
              if (item.placement.isSticky === false) {
                return (<div className="progress">
                  <div
                    className={`progress-bar ${item.cssAddition}`}
                    style={{ width: `${item.measure}%` }}
                  ></div>
                </div>);
              }
              else {
                return (<div className="progress mt-1">
                  <div
                    className={`progress-bar ${item.cssAddition}`}
                    style={{ width: `${item.measure}%` }}
                  ></div>
                </div>);
              }
            })()}
          </div>
        </div>
      ))}
    </React.Fragment>);
}

export default SkillsCard;