import React from "react";
import { MDBBadge } from "mdbreact";
import NavBar from "./components/NavBar";
import Header from "./components/Header";
import Skills from "./components/Skills";
import Timeline from "./components/Timeline";
import TimelineEdu from "./components/TimelineEdu";
// import ContactForm from "./components/ContactForm";
import "./App.css";

function App() {
  return (
    <div className="container-fluid p-0" id="home">
      <NavBar />

      <Header />

      <section id="skills">
        <div className="container">
          <div className="row">
            <div className="col-sm-3 mb-5">
              <Skills title="Front-end Expertise" query="frontend" />
            </div>
            <div className="col-sm-3 mb-5">
              <Skills title="Back-end Expertise" query="backend" />
            </div>
            <div className="col-sm-3 mb-5">
              <Skills title="Database Expertise" query="database" />
            </div>
            <div className="col-sm-3 mb-5">
              <Skills title="E-Business Management" query="management" />
            </div>
          </div>
        </div>
      </section>

      <div className="container mb-5">
        <div className="card">
          <h5 className="card-header">
            What I have used to develop my website
          </h5>
          <div className="card-body">
            <dl className="row mb-0">
              <dt className="col-sm-2">Front-end</dt>
              <dd className="col-sm-10">
                React.js - React Bootstrap - Material Design for React Bootstrap
              </dd>
              <dt className="col-sm-2">Back-end</dt>
              <dd className="col-sm-10">Node.js</dd>
              <dt className="col-sm-2">Database layer</dt>
              <dd className="col-sm-10">MongoDB</dd>
              <dt className="col-sm-2">Server & Pipeline</dt>
              <dd className="col-sm-10">
                Vercel Cloud using Github CI/CD Pipeline
              </dd>
            </dl>
          </div>
        </div>
      </div>

      <section id="experiences">
        <div className="container mb-5">
          <div className="card bg-light">
            <div className="card-body p-0 pb-2">
              <h4 className="ml-5 mt-3 pt-3">Experiences</h4>
              <Timeline query="experiences" />
            </div>
          </div>
        </div>
      </section>

      <section id="educations">
        <div className="container">
          <h2 className="ml-5 mt-3">
            <MDBBadge color="special-color-dark font-weight-normal">
              Educations
            </MDBBadge>
          </h2>
          <TimelineEdu query="educations" />
        </div>
      </section>

      {/* <section id="contact">
        <div className="container mb-5">
          <ContactForm />
        </div>
      </section> */}

      {/* <div className="container">
        <div className="alert alert-success" role="alert">
          <h4 className="alert-heading">Dear Visitors!</h4>
          <p>
            I've decided to move on from ASP.net C# MVC 5 & Razor version of my
            website and re-develop it from scratch using Node.js and React.js.
            As part of Agile and CI/CD practices, you might notice new progress
            in development of this website every passing day.
            <br />
            Also, some features might not work properly while this website is
            under construction.
          </p>
          <hr />
          <p className="mb-0">
            So, Please come back and follow up with latest development.
            <br />
            Thank you!
          </p>
        </div>
      </div> */}

      <footer className="footer">
        <p>
          2020 - {new Date().getFullYear()} Designed and Developed by Hooman
          Babaii Fard
        </p>
      </footer>
    </div>
  );
}

export default App;
